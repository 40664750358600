//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      // 顶部栏初始数
      visibleNumber: 9,
      // 当前激活菜单的 index
      currentIndex: undefined
    };
  },
  computed: {
    theme: function theme() {
      return this.$store.state.settings.theme;
    },
    navIcon: function navIcon() {
      return this.$store.state.settings.navIcon;
    },
    // 所有的路由信息
    routers: function routers() {
      var routers = this.$store.state.permission.topbarRouters;
      var arr = [],
          obj = {};
      routers.forEach(function (item) {
        obj = item;
        obj.path = item.url;
        arr.push(obj);
      });
      return arr;
    },
    // 顶部显示菜单
    topMenus: function topMenus() {
      var topMenus = [];
      this.routers.map(function (menu) {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.child[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    },
    // 默认激活的菜单
    activeMenu: function activeMenu() {
      //简单粗暴的办法，获取当前路由截取一级路由赋值给默认选中菜单
      var key = this.$route.path.split('/')[1];
      key = '/' + key;
      return key;
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.setVisibleNumber);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleNumber);
  },
  mounted: function mounted() {
    this.setVisibleNumber();
  },
  methods: {
    // 根据宽度计算设置显示栏数
    setVisibleNumber: function setVisibleNumber() {
      var width = document.body.getBoundingClientRect().width / 1.8;
      this.visibleNumber = parseInt(width / 85);
    },
    // 菜单选择事件
    handleSelect: function handleSelect(key, keyPath) {
      this.currentIndex = key;

      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (key.indexOf("/redirect") !== -1) {
        // /redirect 路径内部打开
        this.$router.push({
          path: key.replace("/redirect", "")
        });
      } else {
        // 显示左侧联动菜单
        this.activeRoutes(key);
      }
    },
    // 当前激活的路由
    activeRoutes: function activeRoutes(key) {
      var _this = this;

      var routes = [];
      this.routers.map(function (item) {
        if (key == item.url && item.child) {
          //如果选中导航的key值与遍历项的url匹配并且有子级，那么就将该项的子级数组放在routes中
          routes = item.child;
        } else if (key == item.url && !item.child) {
          //只满足选中导航的key值与遍历项的url匹配但是没有子级的情况下，就把这一项赋值给vuex中
          //这一项其实针对控制台，控制台没有子级
          _this.$store.commit("permission/SET_SIDEBAR_ROUTERS", [item]);
        }
      });

      if (routes.length > 0) {
        //routes数组有长度就将它放在vuex中，左侧导航就能读取到，展示的也是选中项的子级
        this.$store.commit("permission/SET_SIDEBAR_ROUTERS", routes);
      }

      return routes;
    },
    ishttp: function ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1;
    }
  }
};