//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AppMain',
  data: function data() {
    return {
      links: [{
        title: '官网',
        key: '1',
        href: 'https://www.mae.vip',
        blankTarget: true
      }, {
        title: '社区',
        key: '2',
        href: 'https://www.mae.vip',
        blankTarget: true
      }, {
        title: '文档',
        key: '3',
        href: 'https://www.mae.vip',
        blankTarget: true
      }],
      copyright: 'Copyright © 2022 烟台蚂蚁摄影信息咨询服务有限公司'
    };
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }
};